/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FormspreeService {
  private http: HttpClient

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler)
  }

  async sendFormData(id: string, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    }

    const response$ = this.http.post<{ next: string; ok: boolean }>(`https://formspree.io/f/${id}`, data, httpOptions)
    const response = await firstValueFrom(response$)

    return response
  }
}
